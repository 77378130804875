import { useSignInForm } from "../auth.actions";
import Spinner from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { Dispatch, SetStateAction } from "react";
import ReactPhoneInput from "react-phone-input-2";

export default function SignIn({
  setTab,
}: {
  setTab: Dispatch<SetStateAction<"sign-in" | "sign-up">>;
}) {
  const { form, handleSignIn } = useSignInForm({ setTab });

  const {
    formState: { errors },
  } = form;

  return (
    <form
      onSubmit={form.handleSubmit(handleSignIn)}
      className="w-full p-6 pb-8 flex flex-col gap-6"
    >
      <h2 className="text-base font-semibold text-label-primary ">
        Insira o seu telefone para continuar:
      </h2>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Telefone</label>
          <ReactPhoneInput
            {...form.register("identifier")}
            value={form.watch("identifier")}
            country={"br"}
            inputStyle={{
              width: "100%",
              height: "56px",
              border: `1px solid ${errors.identifier ? "#f87171" : "#e5e7eb"}`,
              borderRadius: "12px",
            }}
            buttonStyle={{
              height: "56px",
              borderRadius: "12px 0 0 12px",
              backgroundColor: "#f3f4f6",
              border: `1px solid ${errors.identifier ? "#f87171" : "#e5e7eb"}`,
            }}
            copyNumbersOnly
            countryCodeEditable={false}
            onlyCountries={["br"]}
            onChange={(value) => {
              form.setValue("identifier", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </div>
      </div>

      <Button
        disabled={form.formState.isSubmitting}
        className="w-full h-14 flex items-center justify-center gap-2 rounded-xl bg-green-600 hover:bg-green-600 text-base font-medium"
      >
        Continuar
        {form.formState.isSubmitting && <Spinner />}
      </Button>
    </form>
  );
}
