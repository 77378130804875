import AuthenticationDrawer from "@/components/authentication/authentication.drawer";
import BottomNavBar from "@/components/bottom-nav-bar/bottom-nav-bar";
import { useAuth } from "@/context/auth.context";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
  const [authOpen, setAuthOpen] = useState(false);

  const { user } = useAuth();
  const userId = user?.id;

  useEffect(() => {
    if (!userId) {
      setAuthOpen(true);
    }
  }, [user, userId]);

  return (
    <>
      <div className="w-full flex min-h-screen">
        <div className="flex flex-col w-full flex-1">
          <div className="flex-1">
            <Outlet />
          </div>
          <BottomNavBar />
        </div>
      </div>

      <AuthenticationDrawer open={authOpen} setOpen={setAuthOpen} />
    </>
  );
}
