import BottomNavBar from "@/components/bottom-nav-bar/bottom-nav-bar";
import { Outlet } from "react-router-dom";

export default function PublicLayout() {
  return (
    <div className="w-full flex min-h-screen">
      <div className="flex flex-col w-full flex-1">
        <div className="flex-1 flex ">
          <Outlet />
        </div>
        <BottomNavBar />
      </div>
    </div>
  );
}
