import { cn } from "@/lib/utils";
import { UserAddress } from "@/types/user.type";
import { IconCircleDashedCheck, IconPlaystationX } from "@tabler/icons-react";
import { DeleteAddressDialog } from "./deleteAddress.dialog";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useSelectAddress } from "../hooks/address.actions";

export default function AddressCard({ address }: { address: UserAddress }) {
  const [deleteAddressOpen, setDeleteAddressOpen] = useState(false);

  const { selectAddress } = useSelectAddress({ address });

  return (
    <>
      <div className="w-full cursor-pointer" onClick={selectAddress}>
        <div
          className={cn(
            "p-4 rounded-lg border w-full flex items-center justify-between",
            {
              "border-green-300 border-2": address.is_selected,
            }
          )}
        >
          <div className="flex gap-2 items-start justify-start">
            {address.is_selected && (
              <IconCircleDashedCheck size={24} color="#10B981" />
            )}
            <div>
              <h1 className="text-label-primary font-semibold">
                {address.street}, {address.number}
              </h1>
              <p className="text-label-secondary">
                {address.district}, {address.city} - {address.state}
              </p>
            </div>
          </div>
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDeleteAddressOpen(true);
            }}
            className="p-4 border-none"
          >
            <IconPlaystationX size={18} />
          </Button>
        </div>
      </div>

      <DeleteAddressDialog
        open={deleteAddressOpen}
        setOpen={setDeleteAddressOpen}
        address={address}
      />
    </>
  );
}
