import InputMask from "react-input-mask";
import { Input } from "@/components/ui/input";
import React from "react";

const CPFInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => {
  return (
    <InputMask mask="999.999.999-99" alwaysShowMask={false} {...props}>
      <Input ref={ref} placeholder="Seu CPF" className={className} />
    </InputMask>
  );
});

export default CPFInput;
