import { useState, useCallback, useMemo } from "react";
import { IconFilter, IconMenu2 } from "@tabler/icons-react";
import LeftNavBar from "@/components/left-nav-bar/left-nav-bar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Cake,
  Coffee,
  Construction,
  Fish,
  IceCream,
  IceCreamBowl,
  Search,
  ShoppingCart,
  Utensils,
} from "lucide-react";

const foodCategories = [
  { name: "Açai", icon: IceCream },
  { name: "Brasileira", icon: Utensils },
  { name: "Burguer", icon: ShoppingCart },
  { name: "Cafeteria", icon: Coffee },
  { name: "Chinesa", icon: IceCreamBowl },
  { name: "Confeitaria", icon: Cake },
  { name: "Frutos do mar", icon: Fish },
];

const HomePage = () => {
  const [leftNavBarOpen, setLeftNavBarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const filteredCategories = useMemo(() => {
    return foodCategories.filter((category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery]);

  const handleSearch = useCallback((e: any) => {
    setSearchQuery(e.target.value);
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, []);

  const handleCategoryClick = useCallback(
    (categoryName: string) => {
      setSelectedCategory(
        categoryName === selectedCategory ? null : categoryName
      );
    },
    [selectedCategory]
  );

  return (
    <div className="w-full flex flex-col bg-white text-black">
      <header className="sticky top-0 z-10 p-4 flex justify-between items-center bg-white shadow-sm">
        <div className="flex items-center gap-4">
          <img
            src="/logo-typography.svg"
            alt="Pedido.io"
            className="w-28 h-auto"
            loading="eager"
          />
        </div>
        <button
          onClick={() => setLeftNavBarOpen(true)}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Open menu"
        >
          <IconMenu2 size={24} />
        </button>
        <LeftNavBar open={leftNavBarOpen} setOpen={setLeftNavBarOpen} />
      </header>

      <div className="p-4 w-full flex flex-row gap-4 items-center">
        <div className="relative flex-1">
          <Search
            size={16}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <Input
            className="rounded-xl bg-gray-100 pl-10 pr-4 h-11 w-full focus:ring-2 focus:ring-green-500"
            placeholder="Buscar em Pedido.io"
            value={searchQuery}
            onChange={handleSearch}
            aria-label="Search"
          />
        </div>
        <Button
          variant="ghost"
          className="p-2 hover:bg-gray-100 rounded-full"
          aria-label="Filter"
        >
          <IconFilter size={24} />
        </Button>
      </div>

      <ScrollArea className="w-full border-b border-gray-100">
        <div className="flex px-2 py-4">
          {loading
            ? Array(5)
                .fill(0)
                .map((_, i) => (
                  <div key={i} className="flex flex-col items-center mx-2">
                    <Skeleton className="w-12 h-12 rounded-full mb-2" />
                    <Skeleton className="w-16 h-4 rounded" />
                  </div>
                ))
            : filteredCategories.map((category) => (
                <Button
                  key={category.name}
                  variant={
                    selectedCategory === category.name ? "default" : "ghost"
                  }
                  onClick={() => handleCategoryClick(category.name)}
                  className={`
                  flex flex-col items-center px-4 py-2 rounded-lg h-auto min-w-[80px]
                  transition-all duration-200 ease-in-out hover:bg-green-50
                  ${
                    selectedCategory === category.name
                      ? "bg-green-50 text-green-600"
                      : ""
                  }
                `}
                >
                  <category.icon
                    className={`
                  w-6 h-6 mb-1
                  ${
                    selectedCategory === category.name
                      ? "text-green-600"
                      : "text-gray-600"
                  }
                `}
                  />
                  <span className="text-xs whitespace-nowrap">
                    {category.name}
                  </span>
                </Button>
              ))}
        </div>
        <ScrollBar orientation="horizontal" className="bg-gray-100" />
      </ScrollArea>

      <div className="flex flex-col items-center justify-center px-6 py-12 text-center flex-1 mx-auto">
        <div className="animate-bounce mb-6">
          <Construction className="w-16 h-16 text-green-600" />
        </div>
        <h1 className="text-2xl font-bold mb-4 text-gray-800">
          Página em construção
        </h1>
        <p className="text-gray-600 text-base">
          Enquanto isso... acesse diretamente o cardápio de um restaurante
          parceiro para visualizar as opções disponíveis.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
