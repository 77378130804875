import useOrders from "../hook/order.hook";
import LoadingOrdersFallback from "../components/fallbacks/loading-orders.fallback";
import NoHistoryItemFallback from "../components/fallbacks/no-history-item.fallback";
import OrderItem from "../components/order-item";
import { IconChevronLeft } from "@tabler/icons-react";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { useNavigate } from "react-router-dom";

export default function OrdersPage() {
  const navigate = useNavigate();
  const business = useBusinessStore((state) => state.business);

  const { isLoading, orders } = useOrders();

  if (isLoading) return <LoadingOrdersFallback />;

  return (
    <div className="w-full  h-full flex flex-col">
      <div className="px-4 py-4 flex items-center border-b gap-2">
        <button
          onClick={() => {
            if (business?.id) {
              navigate(`/b/${business.id}`);
            } else {
              navigate("/");
            }
          }}
          className="flex items-center justify-center gap-1"
        >
          <IconChevronLeft size={24} />
          <h1 className="text-lg text-label-primary">Meus pedidos</h1>
        </button>
      </div>
      <div className="flex flex-col p-4 gap-4">
        {orders.map((order) => (
          <OrderItem order={order} />
        ))}

        {orders.length === 0 && <NoHistoryItemFallback />}
      </div>
    </div>
  );
}
