import getS3Image from "@/utils/getS3Image.util";
import NoImageFallback from "../no-image.fallback";

export default function BusinessBanner({
  logo_url: banner_url,
  className,
}: {
  logo_url: string | null;
  className: string;
}) {
  if (banner_url) {
    return <img src={getS3Image(banner_url)} className={className} />;
  }
  return <NoImageFallback className={className} />;
}
