import InputMask from "react-input-mask";
import { Input } from "@/components/ui/input";
import React from "react";

const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => {
  return (
    <InputMask alwaysShowMask={false} {...props} mask="+99 (99) 99999-9999">
      <Input
        ref={ref}
        placeholder="Seu número de telefone"
        className={className}
      />
    </InputMask>
  );
});

export default PhoneNumberInput;
