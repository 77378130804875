import MenuItemLogo from "@/components/fallbacks/logos/menu-item.logo";
import { Card, CardContent } from "@/components/ui/card";
import { MenuItem as TypeMenuItem } from "@/types/menu.type";
import formatMoneyValue from "@/utils/formatMoneyValue.util";

type MenuItemProps = {
  item: TypeMenuItem;
};

export default function MenuItem({ item }: MenuItemProps) {
  return (
    <Card key={item.id} className="w-full text-start">
      <CardContent className="p-4 flex gap-4">
        <div className="w-24 h-24 flex-shrink-0">
          <MenuItemLogo
            logo_url={item.logo_url}
            className={
              "min-w-24 min-h-24 max-w-24 max-h-24 rounded-lg object-cover"
            }
          />
        </div>
        <div className="flex-1 min-w-0">
          <h3 className="font-semibold text-base">{item.title}</h3>
          <p className="text-sm text-muted-foreground mt-1 line-clamp-2">
            {item.description}
          </p>
          <p className="text-sm font-medium text-primary mt-2">
            {formatMoneyValue(item.price)}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
