/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from "react";
import { postDiscordMessage } from "../queries/error.queries";
import { useAuth } from "@/context/auth.context";

export default function useErrorPage({ error }: { error: Error }) {
  const { user } = useAuth();

  const sendDiscordMessage = useCallback(
    async function () {
      const errorInfo = {
        message: error.message,
        stack: error.stack,
        name: error.name,
        componentStack: (error as any)?.componentStack,
        href: window.location.href,
        userEmail: user?.email ?? "Usuário deslogado",
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
      };

      await postDiscordMessage({
        channel: "client",
        message: `Error capturado pelo ErrorBoundary\n\`\`\`json\n${JSON.stringify(
          errorInfo,
          null,
          2
        )}\n\`\`\``,
      });
    },
    [error, user?.email]
  );

  useEffect(() => {
    sendDiscordMessage();
  }, [sendDiscordMessage]);
}
