import { useEffect, useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Controller, UseFormReturn } from "react-hook-form";
import { SelectAddressSheet } from "@/components/selectAddress.sheet";
import { useUserStore } from "@/pages/profile/store/user.store";
import { IconBike } from "@tabler/icons-react";
import AuthenticationDrawer from "@/components/authentication/authentication.drawer";
import { useAuth } from "@/context/auth.context";
import { CheckoutStep } from "../cart";
import { CheckoutSchema } from "../schemas/checkout.schemas";
import { cn } from "@/lib/utils";

export default function AddressStep({
  currentStep,
  form,
}: {
  currentStep: CheckoutStep;
  form: UseFormReturn<CheckoutSchema, unknown, undefined>;
}) {
  const { signed } = useAuth();
  const [authOpen, setAuthOpen] = useState(false);

  useEffect(() => {
    if (signed) return setAuthOpen(false);
    if (currentStep === "address") setAuthOpen(true);
  }, [currentStep, signed]);

  if (currentStep !== "address") return null;

  return (
    <div className="flex gap-6 flex-col flex-1 h-full p-4">
      <h3 className="font-semibold text-base">Opções de entrega</h3>

      <Controller
        name="deliveryType"
        control={form.control}
        render={({ field, fieldState }) => (
          <div className="flex flex-col gap-2">
            <RadioGroup
              value={field.value}
              onValueChange={field.onChange}
              className="flex flex-col gap-3"
            >
              <DeliveryOption
                isSelected={field.value === "delivery"}
                onSelect={field.onChange}
                hasError={!!fieldState.error}
                form={form}
              />
              {/* <DineInOption
                isSelected={field.value === "dinein"}
                onSelect={field.onChange}
              />
              <PickupOption
                isSelected={field.value === "pickup"}
                onSelect={field.onChange}
              /> */}
            </RadioGroup>
          </div>
        )}
      />

      <AuthenticationDrawer open={authOpen} setOpen={setAuthOpen} />
    </div>
  );
}

const DeliveryOption = ({
  isSelected,
  form,
  onSelect,
  hasError,
}: {
  isSelected: boolean;
  onSelect: (value: string) => void;
  hasError: boolean;
  form: UseFormReturn<CheckoutSchema>;
}) => {
  const [authOpen, setAuthOpen] = useState(false);
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);

  const { signed } = useAuth();

  const user = useUserStore((state) => state.user);
  const userAddresses = user?.addresses || [];
  const userMainAddress = userAddresses.find((address) => address.is_selected);

  useEffect(() => {
    if (!userMainAddress) return;

    form.setValue("address", userMainAddress);
    form.trigger();
  }, [userMainAddress, form]);

  return (
    <Card
      onClick={() => {
        onSelect("delivery");
      }}
      className={cn(
        "border-2 w-full text-start transition-colors cursor-pointer",
        {
          "border-primary": isSelected && !hasError,
          "border-destructive": hasError && isSelected,
          "border-input": !isSelected,
        }
      )}
    >
      <CardContent className="flex items-center gap-3 p-4">
        <RadioGroupItem value="delivery" className="sr-only" id="delivery" />
        <IconBike
          size={24}
          className={cn("shrink-0", {
            "text-primary": isSelected && !hasError,
            "text-destructive": hasError && isSelected,
            "text-muted-foreground": !isSelected,
          })}
          aria-hidden="true"
        />
        <Label htmlFor="delivery" className="flex-1 cursor-pointer select-none">
          {userMainAddress && (
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col gap-1">
                <p className="text-label-primary font-semibold">
                  {userMainAddress.street}, {userMainAddress.number}
                </p>
                <p className="text-label-secondary">
                  {userMainAddress.district}, {userMainAddress.city} -{" "}
                  {userMainAddress.state}
                </p>
              </div>
            </div>
          )}
          {!userMainAddress && (
            <p
              className={cn("text-label-primary", {
                "font-semibold": isSelected && !hasError,
                "text-destructive": hasError && isSelected,
              })}
            >
              Receber no seu endereço
            </p>
          )}
        </Label>
        <SelectAddressSheet
          open={selectAddressOpen}
          setOpen={setSelectAddressOpen}
        >
          <button
            type="button"
            className={cn("flex items-center justify-center gap-2 underline", {
              "text-primary": isSelected && !hasError,
              "text-destructive": hasError && isSelected,
            })}
            onClick={(e) => {
              e.preventDefault();

              if (!signed) return setAuthOpen(true);

              setSelectAddressOpen(true);
            }}
          >
            {userMainAddress ? "Alterar" : "Selecionar"}
          </button>
        </SelectAddressSheet>

        <AuthenticationDrawer open={authOpen} setOpen={setAuthOpen} />
      </CardContent>
    </Card>
  );
};

// const DineInOption = ({
//   isSelected,
//   onSelect,
// }: {
//   isSelected: boolean;
//   onSelect: (value: string) => void;
// }) => {
//   return (
//     <Card
//       className={cn(
//         "border-2 w-full text-start transition-colors cursor-pointer",
//         {
//           "border-primary": isSelected,
//           "border-input": !isSelected,
//         }
//       )}
//       onClick={() => onSelect("dinein")}
//     >
//       <CardContent className="flex items-center gap-3 p-4">
//         <RadioGroupItem value="dinein" className="sr-only" id="dinein" />
//         <IconBuildingStore
//           size={24}
//           className={cn("shrink-0", {
//             "text-primary": isSelected,
//             "text-muted-foreground": !isSelected,
//           })}
//           aria-hidden="true"
//         />
//         <Label htmlFor="dinein" className="flex-1 cursor-pointer select-none">
//           <p>Consumir no local</p>
//         </Label>
//       </CardContent>
//     </Card>
//   );
// };

// const PickupOption = ({
//   isSelected,
//   onSelect,
// }: {
//   isSelected: boolean;
//   onSelect: (value: string) => void;
// }) => {
//   return (
//     <Card
//       onClick={() => onSelect("pickup")}
//       className={cn(
//         "border-2 w-full text-start transition-colors cursor-pointer",
//         {
//           "border-primary": isSelected,
//           "border-input": !isSelected,
//         }
//       )}
//     >
//       <CardContent className="flex items-center gap-3 p-4">
//         <RadioGroupItem value="pickup" className="sr-only" id="pickup" />
//         <IconWalk
//           size={24}
//           className={cn("shrink-0", {
//             "text-primary": isSelected,
//             "text-muted-foreground": !isSelected,
//           })}
//           aria-hidden="true"
//         />
//         <Label htmlFor="pickup" className="flex-1 cursor-pointer select-none">
//           <p>Retirar no estabelecimento</p>
//         </Label>
//       </CardContent>
//     </Card>
//   );
// };
