import { Outlet } from "react-router-dom";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Toaster } from "sonner";
import { ErrorBoundary } from "react-error-boundary";
import { Analytics } from "@vercel/analytics/react";
import { AuthProvider } from "@/context/auth.context";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorPage } from "@/pages/error/pages/error.page";
import "@/styles/globals.css";

const queryClient = new QueryClient();

export default function RootLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <QueryClientProvider client={queryClient}>
        <Analytics />
        <SpeedInsights />
        <Toaster richColors closeButton position="top-center" />
        <AuthProvider>
          <TooltipProvider>
            <main className="font-inter">
              <Outlet />
            </main>
          </TooltipProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
