import {
  IconArchive,
  IconHome,
  IconToolsKitchen2,
  IconUserCircle,
} from "@tabler/icons-react";
import NavItem from "./nav.item";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useBusinessStore } from "@/pages/business/store/business.store";
import LeftNavBar from "../left-nav-bar/left-nav-bar";
import CartCheckout from "../cart/cart";
import { Button } from "../ui/button";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { cn } from "@/lib/utils";

function CartFeedback() {
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const calculateShoppingCartSubtotal = useBusinessStore(
    (state) => state.calculateShoppingCartSubtotal
  );

  const subtotal = calculateShoppingCartSubtotal();

  const amoutOfItemsOnCart = shoppingCart?.items.length || 0;
  const isCartVisible = amoutOfItemsOnCart > 0;

  if (!isCartVisible) return null;

  return (
    <div className="bg-green-600 border-t w-full p-4 rounded-t-2xl flex items-center justify-between">
      <div className="flex flex-col items-start">
        <p className="text-white font-semibold">Subtotal</p>
        <h1 className="text-white">{formatMoneyValue(subtotal)}</h1>
      </div>
      <div>
        <CartCheckout>
          <Button className="text-sm bg-white text-label-primary hover:bg-white">
            Ver sacola
          </Button>
        </CartCheckout>
      </div>
    </div>
  );
}

export default function BottomNavBar() {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const business = useBusinessStore((state) => state.business);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const amoutOfItemsOnCart = shoppingCart?.items.length || 0;

  const isBusinessRoutes = pathname.includes("/b");
  const isProfileRoutes = pathname.includes("/perfil");
  const isOrdersRoutes = pathname.includes("/pedidos");
  const isHomeRoutes = pathname === "/";
  const isCartVisible = amoutOfItemsOnCart > 0;

  return (
    <>
      <div className="sticky bottom-0 w-full z-40">
        <CartFeedback />

        <div
          className={cn(`w-full p-4 bg-white `, {
            "rounded-t-3xl border": !isCartVisible,
            "rounded-none border-t-2 border-gray-200": isCartVisible,
          })}
        >
          <div className="flex items-center justify-around gap-4">
            {business?.id ? (
              <NavItem
                href={`/b/${business.id}`}
                Icon={IconToolsKitchen2}
                label="Cardápio"
                isActive={isBusinessRoutes}
              />
            ) : (
              <NavItem
                href="/"
                Icon={IconHome}
                label="Início"
                isActive={isHomeRoutes}
              />
            )}

            <NavItem
              href="/pedidos"
              Icon={IconArchive}
              label="Pedidos"
              isActive={isOrdersRoutes}
            />

            <button onClick={() => setOpen(true)}>
              <NavItem
                Icon={IconUserCircle}
                label="Perfil"
                isActive={isProfileRoutes}
              />
            </button>
          </div>
        </div>
      </div>

      <LeftNavBar open={open} setOpen={setOpen} />
    </>
  );
}
