import { Button } from "@/components/ui/button";
import { type Dispatch, type SetStateAction, useEffect, useState } from "react";
import { type CheckoutStep } from "../cart";
import { motion, AnimatePresence } from "framer-motion";
import { IconArrowDown, IconArrowLeft } from "@tabler/icons-react";

export default function Component({
  currentStep,
  setCurrentStep,
  setOpen,
}: {
  currentStep: CheckoutStep;
  setCurrentStep: Dispatch<SetStateAction<CheckoutStep>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBack = () => {
    if (currentStep === "cart") {
      setOpen(false);
    } else if (currentStep === "address") {
      setCurrentStep("cart");
    } else if (currentStep === "payment") {
      setCurrentStep("address");
    } else if (currentStep === "summary") {
      setCurrentStep("payment");
    }
  };

  return (
    <div className="flex flex-col w-full sticky top-0 bg-background z-50">
      <motion.div
        initial={false}
        animate={{
          height: isScrolled ? "auto" : "auto",
          borderBottom: isScrolled ? "1px solid rgb(229 231 235)" : "none",
        }}
        className="flex justify-between items-center px-4 py-3"
      >
        <div className="flex items-center gap-3">
          <Button
            variant="ghost"
            size="icon"
            className="h-9 w-9 rounded-full hover:bg-transparent"
            onClick={handleBack}
          >
            <AnimatePresence mode="wait">
              {currentStep !== "cart" ? (
                <motion.div
                  key="back"
                  initial={{ opacity: 0, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                >
                  <IconArrowLeft size={20} />
                </motion.div>
              ) : (
                <motion.div
                  key="down"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                >
                  <IconArrowDown size={20} />
                </motion.div>
              )}
            </AnimatePresence>
            <span className="sr-only">
              {currentStep !== "cart" ? "Voltar" : "Minimizar"}
            </span>
          </Button>
          <h1 className="text-lg font-semibold">
            {currentStep === "cart" && "Itens do pedido"}
            {currentStep === "address" && "Endereço"}
            {currentStep === "payment" && "Pagamento"}
            {currentStep === "summary" && "Resumo"}
          </h1>
        </div>
      </motion.div>
    </div>
  );
}
