import AuthLayout from "@/layouts/auth.layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();

  // TODO: Criar página home e redirecionar para ela
  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return <AuthLayout></AuthLayout>;
}
