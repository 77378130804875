import { ShoppingBag } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import CartItem from "../components/cart-item";
import { CheckoutStep } from "../cart";
import { useBusinessStore } from "@/pages/business/store/business.store";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { Button } from "@/components/ui/button";
import { Dispatch, SetStateAction } from "react";
import { IconClock } from "@tabler/icons-react";
import { cn } from "@/lib/utils";
import BusinessLogo from "@/components/fallbacks/logos/business-logo";

export default function CartStep({
  currentStep,
  setOpen,
}: {
  currentStep: CheckoutStep;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const business = useBusinessStore((state) => state.business);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const calculateShoppingCartSubtotal = useBusinessStore(
    (state) => state.calculateShoppingCartSubtotal
  );
  const calculateShoppingCartTotal = useBusinessStore(
    (state) => state.calculateShoppingCartTotal
  );

  const total = calculateShoppingCartTotal();
  const subtotal = calculateShoppingCartSubtotal();

  function handleAddMoreItems() {
    if (!business?.id) return;

    setOpen(false);
    scrollTo({ top: 0, behavior: "smooth" });
  }

  if (currentStep !== "cart") return null;

  if (!shoppingCart.items.length) {
    return (
      <div className="flex flex-col items-center justify-center flex-1 gap-4 p-4">
        <div className="flex flex-col items-center justify-center flex-1">
          <ShoppingBag className="w-16 h-16 text-muted-foreground/60" />
          <p className="mt-4 text-muted-foreground">Sacola vazia</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn("flex flex-col rounded-none border-0 flex-1 pb-4 gap-6", {
        "py-6": business?.is_open,
      })}
    >
      {!business?.is_open && (
        <div className="relative z-20 px-4 py-3 bg-destructive/10 border-y border-destructive/20">
          <div className="flex items-center gap-2 text-destructive">
            <IconClock className="h-5 w-5" />
            <p className="text-sm font-medium">
              O estabelecimento está fechado no momento
            </p>
          </div>
        </div>
      )}

      <div className="flex items-center gap-4 px-4">
        <BusinessLogo
          className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-cente"
          logo_url={business?.logo_url ?? null}
        />
        <div>
          <h2 className="font-semibold text-base">{business?.name}</h2>
          <Button
            onClick={handleAddMoreItems}
            variant="link"
            className="text-green-600 h-6 px-0"
          >
            Adicionar mais itens
          </Button>
        </div>
      </div>

      <Separator />

      <div className="flex flex-col gap-4 flex-1 px-4">
        <h2 className="text-lg font-semibold">Itens adicionados</h2>
        {shoppingCart.items.map((item) => (
          <CartItem key={item.id} item={item} />
        ))}
      </div>

      <div className="flex flex-col gap-2 px-4">
        <h2 className="text-lg font-semibold">Resumo de valores</h2>
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex justify-between">
            <span className="text-label-secondary">Subtotal</span>
            <span>{formatMoneyValue(subtotal)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-label-secondary">Taxa de entrega</span>
            <span className="text-label-secondary">A definir</span>
          </div>
          <Separator />
          <div className="flex justify-between font-medium">
            <span className="text-label-primary">Total</span>
            <span className="text-label-primary">
              {formatMoneyValue(total)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
