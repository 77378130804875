import { useParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { getBusinessById } from "../queries/getBusinessById";

export default function useBusiness() {
  const { id } = useParams();
  const [search, setSearch] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("all");

  const setBusiness = useBusinessStore((state) => state.setBusiness);
  const setMenu = useBusinessStore((state) => state.setMenu);
  const setMenuCategories = useBusinessStore(
    (state) => state.setMenuCategories
  );
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);

  function resetBusiness() {
    setBusiness(undefined);
    setMenu(undefined);
    setMenuCategories([]);
    setShoppingCart({
      items: [],
    });
  }

  const { isLoading } = useQuery({
    queryKey: ["business", id],
    queryFn: async () => {
      if (!id) {
        resetBusiness();
        return;
      }

      const data = await getBusinessById(id);

      if (!data) throw new Error("No data returned from getBusinessById");

      const { business, menu, menuCategories } = data;
      setBusiness(business);
      setMenu(menu);
      setMenuCategories(menuCategories);

      return data;
    },
    enabled: !!id,
  });

  const business = useBusinessStore((state) => state.business);
  const menu = useBusinessStore((state) => state.menu);
  const menuCategories = useBusinessStore((state) => state.menuCategories);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);

  const filteredMenuCategories =
    menuCategories
      ?.filter((c) => c.is_active)
      .map((category) => ({
        ...category,
        menu_items: category.menu_items?.filter(
          (item) =>
            item.is_active &&
            (item.title.toLowerCase().includes(search.toLowerCase()) ||
              category.title.toLowerCase().includes(search.toLowerCase()))
        ),
      }))
      .filter((c) => {
        if (selectedCategory === "all") {
          return c.menu_items && c.menu_items.length > 0;
        }
        return (
          c.id.toString() === selectedCategory &&
          c.menu_items &&
          c.menu_items.length > 0
        );
      }) || [];

  const isCategoriesEmpty =
    !filteredMenuCategories || filteredMenuCategories.length <= 0;

  return {
    isLoading,
    business,
    menu,
    menuCategories,
    shoppingCart,
    search,
    setSearch,
    selectedCategory,
    setSelectedCategory,
    isCategoriesEmpty,
    filteredMenuCategories,
  };
}
