import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useAuth } from "@/context/auth.context";
import { useBusinessStore } from "@/pages/business/store/business.store";
import SignIn from "./sign-in";
import SignUp from "./sign-up";

type AuthenticationDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function AuthenticationDrawer({
  open,
  setOpen,
}: AuthenticationDrawerProps) {
  const [tab, setTab] = useState<"sign-in" | "sign-up">("sign-in");

  const { user } = useAuth();
  const navigate = useNavigate();
  const business = useBusinessStore((state) => state.business);

  const userId = user?.id;

  function onAuthDrawerClose() {
    if (userId) return;
    setOpen(false);
    if (business?.id) {
      navigate(`/b/${business.id}`);
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    setTab("sign-in");
  }, [open]);

  useEffect(() => {
    if (userId) setOpen(false);
  }, [userId, setOpen]);

  return (
    <Sheet
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
        onAuthDrawerClose();
      }}
    >
      <SheetContent
        side="bottom"
        className=" h-auto p-0 bg-white rounded-t-3xl overflow-y-auto"
        showCloseButton={false}
      >
        {tab === "sign-in" ? (
          <SignIn setTab={setTab} />
        ) : (
          <SignUp setTab={setTab} />
        )}
      </SheetContent>
    </Sheet>
  );
}
