import api from "@/config/api.config";
import { Business } from "@/types/business.type";
import { Menu, MenuCategory } from "@/types/menu.type";

export async function getBusinessById(id: string) {
  try {
    const { data: business } = await api.get(`/business/${id}`);
    const { data: menu } = await api.get(`/business/${id}/menu`);
    const { data: menuCategories } = await api.get(`/menu/${menu.id}/category`);

    return {
      business: business as Business,
      menu: menu as Menu,
      menuCategories: menuCategories as MenuCategory[],
    };
  } catch (error) {
    console.error(error);

    return undefined;
  }
}
