import { Skeleton } from "@/components/ui/skeleton";

export default function LoadingOrdersFallback() {
  return (
    <div className="w-full   px-8 py-6 h-full flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Meus Pedidos</h1>
      </div>
      <div className="flex items-center justify-start gap-2">
        <Skeleton className="w-60 h-10" />
        <Skeleton className="w-60 h-10" />
      </div>
      <div className="w-full flex flex-col gap-8 ">
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton className="w-full h-40" key={index} />
        ))}
      </div>
    </div>
  );
}
