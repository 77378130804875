import { useSignUpForm } from "../auth.actions";
import { Input } from "@/components/ui/input";
import ReactPhoneInput from "react-phone-input-2";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/ui/spinner";
import { cn } from "@/lib/utils";
import { Dispatch, SetStateAction } from "react";

export default function SignUp({
  setTab,
}: {
  setTab: Dispatch<SetStateAction<"sign-in" | "sign-up">>;
}) {
  const { form, handleSignUp } = useSignUpForm({ setTab });

  const {
    formState: { errors },
  } = form;

  return (
    <form
      onSubmit={form.handleSubmit(handleSignUp)}
      className="w-full p-6 pb-8 flex flex-col gap-6"
    >
      <h2 className="text-base font-semibold text-label-primary ">
        Insira o seu nome e telefone para se cadastrar:
      </h2>

      <div className="flex flex-col gap-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">Nome</label>
          <Input
            autoFocus
            {...form.register("name")}
            placeholder="Insira seu nome"
            className={cn(
              "h-14 px-4 text-base rounded-xl bg-gray-50 border-gray-200",
              {
                "border-red-500": errors.name,
              }
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Telefone</label>
          <ReactPhoneInput
            {...form.register("phone_number")}
            value={form.watch("phone_number")}
            country={"br"}
            inputStyle={{
              width: "100%",
              height: "56px",
              border: `1px solid ${
                errors.phone_number ? "#f87171" : "#e5e7eb"
              }`,
              borderRadius: "12px",
              backgroundColor: "#fafafa",
            }}
            buttonStyle={{
              height: "56px",
              borderRadius: "12px 0 0 12px",
              backgroundColor: "#fafafa",
              border: `1px solid ${
                errors.phone_number ? "#f87171" : "#e5e7eb"
              }`,
            }}
            copyNumbersOnly
            countryCodeEditable={false}
            onlyCountries={["br"]}
            onChange={(value) => {
              form.setValue("phone_number", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </div>
      </div>

      <Button
        disabled={form.formState.isSubmitting}
        className="w-full h-14 flex items-center justify-center gap-2 rounded-xl bg-green-600 hover:bg-green-600 text-base font-medium"
      >
        Cadastrar
        {form.formState.isSubmitting && <Spinner />}
      </Button>
    </form>
  );
}
