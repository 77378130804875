import getS3Image from "@/utils/getS3Image.util";
import NoImageFallback from "../no-image.fallback";

export default function BusinessLogo({
  logo_url,
  className,
}: {
  logo_url: string | null;
  className: string;
}) {
  if (logo_url) {
    return <img src={getS3Image(logo_url)} className={className} />;
  }
  return <NoImageFallback className={className} />;
}
