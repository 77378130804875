export default function getChangedFields<T>(before: T, after: T): Partial<T> {
  const changedFields: Partial<T> = {};

  for (const key in after) {
    const beforeValue = before[key];
    const afterValue = after[key];

    if (
      typeof beforeValue === "object" &&
      beforeValue !== null &&
      !Array.isArray(beforeValue)
    ) {
      // Caso o campo seja um objeto, verifica mudanças recursivamente
      const nestedChanges = getChangedFields(beforeValue, afterValue);
      if (Object.keys(nestedChanges).length > 0) {
        changedFields[key] = nestedChanges as T[Extract<keyof T, string>];
      }
    } else if (Array.isArray(beforeValue) && Array.isArray(afterValue)) {
      // Caso o campo seja um array, compara os arrays
      if (JSON.stringify(beforeValue) !== JSON.stringify(afterValue)) {
        changedFields[key] = afterValue;
      }
    } else if (beforeValue !== afterValue) {
      // Se o valor mudou
      changedFields[key] = afterValue;
    }
  }

  return changedFields;
}
