import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Dispatch, SetStateAction } from "react";
import { useDeleteAddress } from "../hooks/address.actions";
import { UserAddress } from "@/types/user.type";

type DeleteAddressDialogProps = {
  address: UserAddress;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export function DeleteAddressDialog({
  address,
  open,
  setOpen,
}: DeleteAddressDialogProps) {
  const { deleteUserAddress } = useDeleteAddress({ address });

  function handleClose() {
    setOpen(false);
  }

  async function handleDeleteUserAddress() {
    await deleteUserAddress();
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="h-[unset] rounded-lg max-w-[360px] gap-6">
        <DialogHeader className="gap-2">
          <DialogTitle className="text-center">Excluir endereço</DialogTitle>
          <DialogDescription className="text-center">
            Você tem certeza que deseja excluir este endereço?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex items-center justify-center gap-4">
          <Button
            type="submit"
            variant={"outline"}
            className="min-h-14 w-full flex-1 text-label-primary border border-gray-300 hover:border-gray-400 rounded-lg"
            onClick={handleDeleteUserAddress}
          >
            Sim
          </Button>
          <Button
            type="submit"
            className="bg-green-400 w-full min-h-14 flex-1 text-label-primary hover:bg-green-500 rounded-lg !m-0"
            onClick={handleClose}
          >
            Não
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
