import { useLocation } from "react-router-dom";
import UserHeader from "./user.header";
import NavItem from "./nav.item";
import {
  IconArchive,
  IconHome,
  IconMapPin,
  IconToolsKitchen2,
  IconUser,
} from "@tabler/icons-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import LogoutButton from "../logout.button";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useAuth } from "@/context/auth.context";
import { Sheet, SheetContent } from "../ui/sheet";
import { useBusinessStore } from "@/pages/business/store/business.store";

type LeftNavBarProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export default function LeftNavBar({ open, setOpen }: LeftNavBarProps) {
  const { user, signed } = useAuth();
  const { pathname } = useLocation();

  const business = useBusinessStore((state) => state.business);

  const isBusinessRoutes = pathname.includes("/b");
  const isProfileRoutes = pathname.includes("/perfil");
  const isOrdersRoutes = pathname.includes("/pedidos");
  const isAddressRoutes = pathname.includes("/enderecos");
  const isHomeRoutes = pathname === "/";

  function handleClose() {
    if (setOpen) {
      scrollTo(0, 0);
      setOpen(false);
    }
  }

  useEffect(() => {
    if (!signed) return setOpen(false);
  }, [setOpen, signed]);

  return (
    <Sheet onOpenChange={setOpen} open={open}>
      <SheetContent side="right" className="w-full p-0">
        <nav className="flex flex-col items-start gap-8 p-4 pt-0 xl:pt-4 flex-1 font-inter pointer-events-auto">
          <div className="w-full flex items-center justify-between gap-4">
            <UserHeader />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <NavItem
              href="/perfil"
              icon={<IconUser size={24} className="stroke-label-primary" />}
              label="Meu perfil"
              isActive={isProfileRoutes}
              tooltipText="Meu perfil"
              handleOnClick={handleClose}
            />

            {business?.id ? (
              <NavItem
                href={`/b/${business.id}`}
                icon={
                  <IconToolsKitchen2
                    size={24}
                    className="stroke-label-primary"
                  />
                }
                label="Cardápio"
                isActive={isBusinessRoutes}
                tooltipText="Cardápio"
                handleOnClick={handleClose}
              />
            ) : (
              <NavItem
                href={"/"}
                icon={<IconHome size={24} className="stroke-label-primary" />}
                label="Início"
                isActive={isHomeRoutes}
                tooltipText="Início"
                handleOnClick={handleClose}
              />
            )}

            <NavItem
              href="/pedidos"
              icon={<IconArchive size={24} className="stroke-label-primary" />}
              label="Meus pedidos"
              isActive={isOrdersRoutes}
              tooltipText="Pedidos"
              handleOnClick={handleClose}
            />
            <NavItem
              href="/enderecos"
              icon={<IconMapPin size={24} className="stroke-label-primary" />}
              label="Meus endereços"
              isActive={isAddressRoutes}
              tooltipText="Meus endereços"
              handleOnClick={handleClose}
            />
            {user && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <LogoutButton />
                </TooltipTrigger>
                <TooltipContent side="right">Sair</TooltipContent>
              </Tooltip>
            )}
          </div>
        </nav>
      </SheetContent>
    </Sheet>
  );
}
