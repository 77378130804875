import { MapPin, Search } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export default function LoadingBusinessFallback() {
  return (
    <div className="w-full flex flex-col min-h-screen bg-gray-50">
      <div className="relative">
        <div className="h-40 w-full overflow-hidden">
          <Skeleton className="w-full h-full" />
        </div>
        <div className="absolute -bottom-6 left-4">
          <div className="rounded-full border-4 border-white overflow-hidden">
            <Skeleton className="w-20 h-20" />
          </div>
        </div>
      </div>

      <div className="px-4 pt-8 pb-4">
        <Skeleton className="h-8 w-3/4 mb-2" />
        <Skeleton className="h-4 w-1/2 mb-2" />
        <Skeleton className="h-4 w-1/3" />
      </div>

      <div className="px-4">
        <Button variant="outline" className="w-full justify-start py-6">
          <MapPin className="mr-2 h-5 w-5" />
          <Skeleton className="w-full h-5" />
        </Button>
      </div>

      <Card className="mx-4 mt-4">
        <CardContent className="p-4">
          <Skeleton className="h-20" />
        </CardContent>
      </Card>

      <div className="flex gap-2 p-4">
        <Skeleton className="h-10 w-full" />
        <Button variant="outline" size="icon">
          <Search className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex flex-col gap-6">
        {Array.from({ length: 3 }).map(() => (
          <div className="px-4">
            <Skeleton className="mb-4 h-6 w-3/4" />
            <Skeleton className="mb-4 h-4 w-1/2" />
            <Skeleton className="mb-4 h-24" />
          </div>
        ))}
      </div>
    </div>
  );
}
