import { z } from "zod";

export const addressSchema = z.object({
  user_id: z.number(),
  city: z.string().min(3, "Cidade inválida"),
  street: z.string().min(3, "Rua inválida"),
  state: z.string().min(2, "Estado inválido"),
  district: z.string().min(3, "Bairro inválido"),
  postal_code: z.string().min(8, "CEP inválido"),
  number: z.coerce
    .number({
      message: "Número inválido",
    })
    .min(1, "O número é obrigatório"),
  info: z.string().nullable().optional(),
  lat: z.number().nullable().optional(),
  lng: z.number().nullable().optional(),
  is_selected: z.boolean().nullable().optional(),
});

export type AddressForm = z.infer<typeof addressSchema>;
