import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { GoogleSearchAddress } from "@/types/user.type";
import { Dispatch, SetStateAction } from "react";
import { useAddAddress } from "../hooks/address.actions";

type DeleteAddressDialogProps = {
  address: GoogleSearchAddress;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSearchInput: Dispatch<SetStateAction<string>>;
};

export function AddAddressDrawer({
  address,
  open,
  setOpen,
  setSearchInput,
}: DeleteAddressDialogProps) {
  const { createUserAddress, form } = useAddAddress({ address });

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        form.reset();
        setOpen(value);
      }}
    >
      <DialogContent className="w-full gap-6 flex flex-col py-8 justify-center sm:rounded-none md:rounded-xl  ">
        <DialogHeader className="gap-4 h-[unset] ">
          <DialogTitle className="text-center">Confirme endereço</DialogTitle>
          <DialogDescription className="text-center">
            {address.formatted_address}
          </DialogDescription>
        </DialogHeader>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            await createUserAddress(data);
            setOpen(false);
            setSearchInput("");
          })}
          className="flex flex-col gap-6"
        >
          <div className="px-4  items-center justify-center gap-4 flex flex-col w-full">
            <div className="w-full  items-center justify-center gap-4 grid grid-cols-2">
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.city && (
                  <span className="text-red-500">
                    {form.formState.errors.city.message}
                  </span>
                )}
                <Input
                  {...form.register("city")}
                  placeholder="Cidade"
                  className="py-6 px-4"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.state && (
                  <span className="text-red-500">
                    {form.formState.errors.state.message}
                  </span>
                )}
                <Input
                  {...form.register("state")}
                  placeholder="Estado"
                  className="py-6 px-4"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.street && (
                  <span className="text-red-500">
                    {form.formState.errors.street.message}
                  </span>
                )}
                <Input
                  {...form.register("street")}
                  placeholder="Rua"
                  className="py-6 px-4"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.district && (
                  <span className="text-red-500">
                    {form.formState.errors.district.message}
                  </span>
                )}
                <Input
                  {...form.register("district")}
                  placeholder="Bairro"
                  className="py-6 px-4"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.postal_code && (
                  <span className="text-red-500">
                    {form.formState.errors.postal_code.message}
                  </span>
                )}
                <Input
                  {...form.register("postal_code")}
                  placeholder="CEP"
                  className="py-6 px-4"
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                {form.formState.errors.number && (
                  <span className="text-red-500">
                    {form.formState.errors.number.message}
                  </span>
                )}
                <Input
                  {...form.register("number")}
                  placeholder="Número"
                  className="py-6 px-4"
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              {form.formState.errors.info && (
                <span className="text-red-500">
                  {form.formState.errors.info.message}
                </span>
              )}
              <Input
                {...form.register("info")}
                placeholder="Complemento"
                className="py-6 px-4"
              />
            </div>
          </div>

          <DialogFooter className="flex items-center justify-center gap-4 px-4">
            <DialogClose className="p-4 w-full flex-1 text-label-primary border border-gray-300 hover:border-gray-400 rounded-lg">
              Cancelar
            </DialogClose>
            <Button
              type="submit"
              className="bg-green-400  h-full w-full p-4 flex-1 text-label-primary border hover:bg-green-500 rounded-lg !m-0"
            >
              {form.formState.isSubmitting ? "Adicionando..." : "Adicionar"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
