import api from "@/config/api.config";
import { Business } from "@/types/business.type";
import { Order } from "@/types/order.type";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import { User, UserAddress } from "@/types/user.type";

export type PostOrderProps = {
  user: User;
  business: Business;
  items: ShoppingCartItem[];
  address: Pick<
    UserAddress,
    "street" | "number" | "district" | "city" | "state" | "postal_code" | "info"
  >;
  payment_method: {
    name: string;
    flag: string;
  };
  description?: string;
  change_amount?: number | null;
};

export async function postOrder({
  description,
  items,
  payment_method,
  user,
  business,
  address,
  change_amount,
}: PostOrderProps) {
  const { data: order } = await api.post("/order", {
    type: "automatic",
    business_id: business.id,
    user_id: user.id,
    description,
    status: "pending",
    metadata: {
      items,
      user: {
        id: user.id,
        name: user.name,
        phone_number: user.phone_number,
        address: address,
      },
      business: {
        id: business.id,
        name: business.name,
        phone_number: business.phone_number,
        logo_url: business.logo_url,
        banner_url: business.banner_url,
      },
      payment_method,
      change_amount,
    },
  });

  return order as Order;
}
