import api from "@/config/api.config";
import { useUserStore } from "@/pages/profile/store/user.store";
import { Order } from "@/types/order.type";
import { useQuery } from "@tanstack/react-query";

export default function useOrders() {
  const user = useUserStore((state) => state.user);

  const { data: orders = [], isLoading } = useQuery({
    queryKey: ["orders", user?.id],
    queryFn: async () => {
      if (!user) return [];

      const { data } = await api.get<Order[]>(`/order/user/${user.id}`);

      return data ?? [];
    },
    enabled: !!user,
  });

  function sortOrders(orders: Order[]) {
    return [...orders].sort((a, b) => {
      if (a.pending_at > b.pending_at) return -1;
      return 1;
    });
  }

  const sortedOrders = sortOrders(orders);

  return {
    orders: sortedOrders,
    isLoading,
  };
}
