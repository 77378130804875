import { useUserStore } from "@/pages/profile/store/user.store";
import { Button } from "../ui/button";
import { useState } from "react";
import AuthenticationDrawer from "../authentication/authentication.drawer";
import formatName from "@/utils/formatName.util";
import { useAuth } from "@/context/auth.context";
import NoProfileFallback from "../fallbacks/no-profile.fallback";

export default function UserHeader() {
  const [authDialogOpen, setAuthDialogOpen] = useState(false);

  const { user } = useAuth();
  const userStore = useUserStore((state) => state.user);

  function handleLogin() {
    setAuthDialogOpen(true);
  }

  return (
    <>
      <div className="w-full flex items-center justify-between gap-4 font-inter">
        <div className="flex items-center justify-center gap-4">
          <NoProfileFallback className="min-w-10 min-h-10 max-w-16 max-h-16" />
          <div className="flex items-start justify-start flex-col">
            {userStore && (
              <h1 className="font-bold text-label-primary line-clamp-2">
                {formatName(userStore.name)}
              </h1>
            )}
            {!user && (
              <Button
                onClick={handleLogin}
                className="bg-transparent hover:bg-transparent text-label-primary text-lg font-medium p-0 "
              >
                Entrar
              </Button>
            )}
          </div>
        </div>
      </div>

      <AuthenticationDrawer open={authDialogOpen} setOpen={setAuthDialogOpen} />
    </>
  );
}
