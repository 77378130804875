import { Separator } from "../../../components/ui/separator";
import { ProfileInfoForm } from "../components/info/form/profile-info.form";

export default function ProfileInfoPage() {
  return (
    <div className="w-full space-y-6">
      <div>
        <h3 className="text-lg font-medium">Informações</h3>
        <p className="text-base text-muted-foreground">
          Essas são as suas informações de perfil.
        </p>
      </div>
      <Separator />
      <ProfileInfoForm />
    </div>
  );
}
