import { differenceInDays, format, formatRelative } from "date-fns";
import { ptBR } from "date-fns/locale";

export const formatDateToNaturalLanguage = (date: string | Date) => {
  if (differenceInDays(new Date(), new Date(date)) > 6) {
    return format(new Date(date), "dd/MM/yyyy, 'às' HH:mm");
  }

  const formattedDate = formatRelative(new Date(date), Date.now(), {
    locale: ptBR,
  });

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};
