import { Button } from "@/components/ui/button";
import { CheckoutStep } from "../cart";
import { Dispatch, SetStateAction } from "react";
import { useBusinessStore } from "@/pages/business/store/business.store";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { UseFormReturn } from "react-hook-form";
import { CheckoutSchema } from "../schemas/checkout.schemas";
import { useUserStore } from "@/pages/profile/store/user.store";
import { postOrder, PostOrderProps } from "../queries/postOrder";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export default function NextStepButton({
  currentStep,
  setCurrentStep,
  setOpen,
  form,
}: {
  currentStep: CheckoutStep;
  setCurrentStep: Dispatch<SetStateAction<CheckoutStep>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  form: UseFormReturn<CheckoutSchema>;
}) {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const business = useBusinessStore((state) => state.business);
  const setBusiness = useBusinessStore((state) => state.setBusiness);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);
  const calculateShoppingCartTotal = useBusinessStore(
    (state) => state.calculateShoppingCartTotal
  );
  const calculateShoppingCartSubtotal = useBusinessStore(
    (state) => state.calculateShoppingCartSubtotal
  );
  const isShoppingCartEmpty = useBusinessStore(
    (state) => state.isShoppingCartEmpty
  );

  const total = calculateShoppingCartTotal();
  const subtotal = calculateShoppingCartSubtotal();

  const buttonText = {
    cart: `Continuar • ${formatMoneyValue(subtotal)}`,
    address: "Continuar",
    payment: `Revisar pedido • ${formatMoneyValue(total)}`,
    summary: "Confirmar",
  };

  const isBagEmpty = isShoppingCartEmpty();

  const handleContinue = async () => {
    if (currentStep === "cart" && shoppingCart.items.length === 0) return;

    if (currentStep === "address") {
      const deliveryType = form.watch("deliveryType");

      if (deliveryType === "delivery") {
        const hasAddress = user?.addresses?.some((addr) => addr.is_selected);
        if (!hasAddress) {
          return form.setError("deliveryType", {
            type: "manual",
            message: "Selecione um endereço para entrega",
          });
        }
      }
    }

    if (currentStep === "payment") {
      const paymentMethod = form.watch("paymentMethod");

      if (!paymentMethod) {
        return form.setError("paymentMethod", {
          type: "manual",
          message: "Selecione um método de pagamento",
        });
      }
    }

    if (currentStep === "summary") {
      if (!user || !business) return;

      if (!form.formState.isValid) return form.trigger();

      const body: PostOrderProps = {
        user,
        business,
        items: shoppingCart.items,
        address: form.getValues("address"),
        payment_method: form.getValues("paymentMethod"),
        description: form.getValues("description"),
        change_amount: form.getValues("changeAmount"),
      };

      try {
        await form.handleSubmit(async () => {
          return await postOrder(body);
        })();

        setShoppingCart({
          items: [],
        });

        form.reset();

        navigate("/pedidos");

        toast.success("Pedido realizado com sucesso!");

        setOpen(false);
      } catch (e: any) {
        if (e.response?.data?.message?.includes("Business is closed")) {
          toast.error("O estabelecimento está fechado no momento.");

          setBusiness({
            ...business,
            is_open: false,
          });
        }

        return console.error(e);
      }
    }

    const nextSteps: Record<CheckoutStep, CheckoutStep> = {
      cart: "address",
      address: "payment",
      payment: "summary",
      summary: "cart",
    };

    scrollTo({ top: 0, behavior: "instant" });
    setCurrentStep(nextSteps[currentStep]);
  };

  if (!business?.is_open) {
    return (
      <div className="p-4 border-t sticky bottom-0 bg-background z-10">
        <Button
          type="submit"
          disabled
          className="w-full bg-red-600 hover:bg-red-600 text-white py-6 text-lg font-medium"
        >
          Fechado
        </Button>
      </div>
    );
  }

  if (isBagEmpty)
    return (
      <div className="p-4 border-t sticky bottom-0 bg-background z-10">
        <Button
          type="submit"
          disabled
          className="w-full bg-green-600 hover:bg-green-600 text-white py-6 text-lg font-medium"
        >
          Sacola vazia
        </Button>
      </div>
    );

  if (currentStep === "summary")
    return (
      <div className="p-4 border-t sticky bottom-0 bg-background z-10">
        <Button
          disabled={form.formState.isSubmitting}
          type="submit"
          className="w-full bg-green-600 hover:bg-green-600 text-white py-6 text-lg font-medium"
          onClick={handleContinue}
        >
          {form.formState.isSubmitting && "Enviando pedido, aguarde..."}
          {!form.formState.isSubmitting && buttonText[currentStep]}
        </Button>
      </div>
    );

  return (
    <div className="p-4 border-t sticky bottom-0 bg-background z-10">
      <Button
        type="button"
        className="w-full bg-green-600 hover:bg-green-600 text-white py-6 text-lg font-medium"
        onClick={handleContinue}
      >
        {buttonText[currentStep]}
      </Button>
    </div>
  );
}
