import axios from "axios";

const CLIENT_AUTH_TOKEN = localStorage.getItem("CLIENT_AUTH_TOKEN");
const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  withCredentials: true,
  timeout: 7000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${CLIENT_AUTH_TOKEN}`,
  },
});

export default api;
