import { Business } from "@/types/business.type";
import { Menu, MenuCategory, MenuItem } from "@/types/menu.type";
import { ShoppingCart } from "@/types/shopping-cart.type";
import { create } from "zustand";

type BusinessStore = {
  isLoading: boolean;
  business: Business | undefined;
  menu: Menu | undefined;
  menuCategories: MenuCategory[];
  menuItems: MenuItem[];
  shoppingCart: ShoppingCart;

  setIsLoading: (value: boolean) => void;
  setBusiness: (business: Business | undefined) => void;
  setMenu: (menu: Menu | undefined) => void;
  setMenuCategories: (menuCategories: MenuCategory[]) => void;
  setMenuItems: (menuItems: MenuItem[]) => void;
  setShoppingCart: (ShoppingCart: ShoppingCart) => void;

  calculateShoppingCartDeliveryFee: () => number;
  calculateShoppingCartSubtotal: () => number;
  calculateShoppingCartTotal: () => number;
  isShoppingCartEmpty: () => boolean;
};

export const useBusinessStore = create<BusinessStore>((set, get) => ({
  isLoading: false,
  business: undefined,
  menu: undefined,
  menuCategories: [],
  menuItems: [],
  shoppingCart: {
    items: [],
  },

  setIsLoading: (isLoading) =>
    set({
      isLoading,
    }),

  setBusiness: (business) =>
    set({
      business,
    }),

  setMenu: (menu) =>
    set({
      menu,
    }),

  setMenuCategories: (menuCategories) =>
    set({
      menuCategories,
    }),

  setMenuItems: (menuItems) =>
    set({
      menuItems,
    }),

  setShoppingCart: (shoppingCart) => set({ shoppingCart }),

  calculateShoppingCartDeliveryFee: () => {
    return 0;
    // const business = get().business;
    // return business?.deliveryFee || 0;
  },

  // Calcula o subtotal do carrinho com base nos preços e quantidades dos itens
  calculateShoppingCartSubtotal: () => {
    const { shoppingCart } = get();
    return shoppingCart.items.reduce((subtotal, item) => {
      const itemPrice = item.price || 0;
      return subtotal + itemPrice * item.quantity;
    }, 0);
  },

  // Calcula o total final do carrinho, incluindo a taxa de entrega
  calculateShoppingCartTotal: () => {
    const subtotal = get().calculateShoppingCartSubtotal();
    const deliveryFee = get().calculateShoppingCartDeliveryFee();
    return subtotal + deliveryFee;
  },

  isShoppingCartEmpty: () => {
    const { shoppingCart } = get();
    return shoppingCart.items.length === 0;
  },
}));
