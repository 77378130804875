import { useUserStore } from "@/pages/profile/store/user.store";
import { GoogleSearchAddress, User, UserAddress } from "@/types/user.type";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { addressSchema, AddressForm } from "../schemas/address.schemas";
import {
  deleteUserAddress as queryDeleteUserAddress,
  patchUserAddress,
  postUserAddress,
} from "../queries/address.queries";

export function useAddAddress({ address }: { address: GoogleSearchAddress }) {
  const user = useUserStore((state) => state.user) as User;
  const setUser = useUserStore((state) => state.setUser);

  const form = useForm<AddressForm>({
    resolver: zodResolver(addressSchema),
    values: {
      ...address,
      user_id: user?.id,
    },
    defaultValues: {
      number: undefined,
    },
  });

  async function createUserAddress(body: AddressForm) {
    if (!user) return;

    try {
      const data = await postUserAddress(body);

      const newAddresses = [...(user.addresses || []), data].map((a) => {
        return {
          ...a,
          is_selected: a.id === data.id,
        };
      });

      setUser({ ...user, addresses: newAddresses });

      form.reset(undefined, {
        keepDefaultValues: false,
        keepValues: false,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return {
    form,
    createUserAddress,
  };
}

export function useSelectAddress({ address }: { address: UserAddress }) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  async function selectAddress() {
    if (address.is_selected) return;
    if (!address.id) return;
    if (!user) return;

    try {
      await patchUserAddress(user.id, address.id, {
        is_selected: true,
      });

      const addresses = user.addresses || [];

      const newAddresses = addresses.map((a) => {
        return {
          ...a,
          is_selected: a.id === address.id,
        };
      });

      setUser({
        ...user,
        addresses: newAddresses,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return {
    selectAddress,
  };
}

export function useDeleteAddress({ address }: { address: UserAddress }) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  async function deleteUserAddress() {
    if (!user) return;

    const userAddresses = user.addresses || [];

    try {
      await queryDeleteUserAddress(address.user_id, address.id);

      const newAddresses = userAddresses.filter((a) => a.id !== address.id);
      setUser({ ...user, addresses: newAddresses });
    } catch (err) {
      console.error(err);
    }
  }

  return {
    deleteUserAddress,
  };
}
