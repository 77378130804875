import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Order } from "@/types/order.type";
import { formatDateToNaturalLanguage } from "@/utils/formatDateToNaturalLanguage.util";
import { typesTraductions } from "@/data/typesTraductions";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { ViewOrderSheet } from "./view.order.sheet";
import formatMoneyValue from "@/utils/formatMoneyValue.util";

export default function OrderItem({ order }: { order: Order }) {
  const [open, setOpen] = useState(false);

  const value = order.metadata.items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <>
      <Card key={order.id} className="h-[unset] flex flex-col gap-4">
        <CardHeader className="flex flex-row items-center justify-start gap-6 px-4 w-full flex-wrap pb-0 pt-4">
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex flex-col gap-1">
              <h1 className="text-lg text-label-primary font-semibold">
                Pedido #{order.id}
              </h1>
              <div className="flex flex-row items-center justify-between gap-1">
                <span className="text-label-secondary text-base">
                  {formatDateToNaturalLanguage(order.pending_at)}
                </span>
              </div>
            </div>
            <Badge
              className={cn("text-white text-[11px]  font-semibold", {
                "bg-gray-600": order.status === "pending",
                "bg-green-600": order.status === "done",
                "bg-red-600": order.status === "canceled",
                "bg-yellow-600": order.status === "production",
                "bg-blue-600": order.status === "delivering",
              })}
            >
              {typesTraductions[order.status]}
            </Badge>
          </div>
        </CardHeader>
        <CardContent className="gap-2 flex items-center justify-start flex-wrap px-4 pb-0">
          <div className="w-full flex flex-col gap-1 items-start justify-center text-start">
            {order.metadata.items.map((i, index) => (
              <p className="text-label-primary" key={index}>
                {i.quantity}x {i.title}
              </p>
            ))}
            <span className="font-semibold  text-label-primary text-base">
              {formatMoneyValue(value)}
            </span>
          </div>
        </CardContent>
        <CardFooter className="px-4">
          <Button
            variant={"ghost"}
            className={cn("border text-green-600 flex-1", {
              "text-gray-600 border-gray-600": order.status === "pending",
              "text-green-600 border-green-600": order.status === "done",
              "text-red-600 border-red-600": order.status === "canceled",
              "text-yellow-600 border-yellow-600":
                order.status === "production",
              "text-blue-600 border-blue-600": order.status === "delivering",
            })}
            onClick={() => setOpen(true)}
          >
            Detalhes
          </Button>
        </CardFooter>
      </Card>

      <ViewOrderSheet open={open} setOpen={setOpen} order={order} />
    </>
  );
}
