import { Business, BusinessWekDays } from "@/types/business.type";
import isBusinessInOperationTime from "./isBusinessInOperationTime.util";

export default function isBusinessOpenUntil(business: Business): string {
  const today = new Date();
  const currentDay: BusinessWekDays = (
    today.getDay() + 1
  ).toString() as BusinessWekDays;

  const todayHours = business.metadata.hours.find(
    (hours) => hours.day_of_week === currentDay
  );

  const isBusinessOpen = isBusinessInOperationTime(business);

  if (!isBusinessOpen) {
    return "Fechado";
  }

  if (!todayHours) {
    return "Aberto";
  }

  const endTime = todayHours.end_time;

  if (!endTime) {
    return "Aberto";
  }

  return `Aberto até às ${endTime}`;
}
