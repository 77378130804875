import ProfileInfoPage from "./profile-info.page";

export default function ProfilePage() {
  return (
    <div className="p-8 flex w-full flex-col h-full flex-1">
      <div className="flex flex-col gap-4 h-full flex-1">
        <div className="flex items-start justify-center gap-6 w-full flex-col">
          <div className="w-full flex items-center justify-between gap-4">
            <div className="w-full">
              <h1 className="text-2xl font-semibold">Perfil</h1>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-start justify-start overflow-hidden h-full">
          <div className="w-full flex rounded-lg border p-6">
            <ProfileInfoPage />
          </div>
        </div>
      </div>
    </div>
  );
}
