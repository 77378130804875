import getS3Image from "@/utils/getS3Image.util";
import NoFoodImageFallback from "../no-food-image.fallback";

export default function MenuItemLogo({
  logo_url,
  className,
}: {
  logo_url: string | null;
  className: string;
}) {
  if (logo_url) {
    return <img src={getS3Image(logo_url)} className={className} />;
  }
  return <NoFoodImageFallback className={className} />;
}
