import { UserAddress } from "../types/user.type";

export const formatUserAddress = (address: UserAddress): string => {
  if (!address) return "-";

  const addressParts = [
    address.street,
    address.number,
    address.info,
    address.district,
    address.postal_code,
    address.state,
    address.city,
  ]
    .map((part) =>
      part !== undefined && part !== null ? String(part).trim() : ""
    )
    .filter((part) => part !== "");

  return addressParts.length > 0 ? addressParts.join(", ") : "-";
};
