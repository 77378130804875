import { Button } from "@/components/ui/button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { MenuCategory } from "@/types/menu.type";
import { IconSearch, IconX } from "@tabler/icons-react";
import { useState, useEffect, useRef } from "react";
import { Input } from "@/components/ui/input";

type BusinessFeaturesProps = {
  categories: MenuCategory[];
  onCategoryChange: (value: string) => void;
  selectedCategory: string;
  onSearch: (value: string) => void;
  searchValue: string;
};

export default function BusinessFeatures({
  categories,
  onCategoryChange,
  selectedCategory,
  onSearch,
  searchValue,
}: BusinessFeaturesProps) {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const collapseTimerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (collapseTimerRef.current) {
        clearTimeout(collapseTimerRef.current);
      }
    };
  }, []);

  const startCollapseTimer = () => {
    if (collapseTimerRef.current) {
      clearTimeout(collapseTimerRef.current);
    }

    if (!searchValue) {
      collapseTimerRef.current = setTimeout(() => {
        setIsSearchExpanded(false);
      }, 3000); // 3 seconds delay before collapsing
    }
  };

  const cancelCollapseTimer = () => {
    if (collapseTimerRef.current) {
      clearTimeout(collapseTimerRef.current);
    }
  };

  const toggleSearch = () => {
    if (isSearchExpanded && searchValue) {
      onSearch("");
    }
    setIsSearchExpanded(!isSearchExpanded);
    cancelCollapseTimer();

    // Focus the input after expanding
    if (!isSearchExpanded) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleInputBlur = () => {
    startCollapseTimer();
  };

  const handleInputFocus = () => {
    cancelCollapseTimer();
  };

  return (
    <>
      {/* <div className="px-4">
        <Button variant="outline" className="w-full justify-start py-6">
          <IconMapPin className="mr-2 h-5 w-5" />
          Calcular taxa e tempo de entrega
        </Button>
      </div> */}

      <div className="flex gap-2 p-4">
        {!isSearchExpanded && (
          <div
            className={`transition-all duration-300 ease-in-out ${
              isSearchExpanded ? "w-0 opacity-0" : "w-full opacity-100"
            }`}
          >
            <Select
              value={selectedCategory}
              onValueChange={onCategoryChange}
              disabled={isSearchExpanded}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Lista de categorias" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">Todas as categorias</SelectItem>
                {categories.map((category) => (
                  <SelectItem key={category.id} value={category.id.toString()}>
                    {category.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}

        <div
          className={`transition-all duration-300 ease-in-out flex items-center gap-2 ${
            isSearchExpanded ? "w-full" : "w-10"
          }`}
        >
          {isSearchExpanded && (
            <Input
              ref={inputRef}
              type="text"
              placeholder="Buscar produtos..."
              value={searchValue}
              onChange={(e) => {
                onSearch(e.target.value);
                if (!e.target.value) {
                  startCollapseTimer();
                } else {
                  cancelCollapseTimer();
                }
              }}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              className="w-full transition-all duration-300 ease-in-out"
            />
          )}
          <Button
            variant="outline"
            size="icon"
            onClick={toggleSearch}
            className="flex-shrink-0"
          >
            {isSearchExpanded && searchValue ? (
              <IconX className="h-4 w-4" />
            ) : (
              <IconSearch className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>
    </>
  );
}
