import { cn } from "@/lib/utils";
import { ElementType, ReactNode } from "react";
import { Link } from "react-router-dom";

type NavItemProps = {
  href?: string;
  Icon: ElementType;
  label: string;
  isActive: boolean;
};

function NavWrapper({
  href,
  children,
}: {
  href?: string;
  children: ReactNode;
}) {
  if (href) {
    return (
      <Link
        to={href}
        type="button"
        className="flex items-center justify-center flex-col"
      >
        {children}
      </Link>
    );
  }

  return (
    <div className="flex items-center justify-center flex-col">{children}</div>
  );
}

export default function NavItem({ href, Icon, label, isActive }: NavItemProps) {
  return (
    <NavWrapper href={href}>
      <div className="">
        <Icon
          className={cn("", {
            "stroke-green-700": isActive,
            "opacity-60": !isActive,
          })}
        />
      </div>
      <p
        className={cn(
          "text-label-secondary text-center",
          "text-sm md:text-base",
          {
            "text-green-700 font-semibold": isActive,
          }
        )}
      >
        {label}
      </p>
    </NavWrapper>
  );
}
