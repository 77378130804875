import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import getChangedFields from "@/utils/getChangedFields.util";
import api from "@/config/api.config";
import { ProfileForm, profileFormSchema } from "../schemas/profile.schemas";
import { useUserStore } from "@/pages/profile/store/user.store";

// TODO: Validação correta de cep
// TODO: Validação correta de cpf
// TODO: Validação correta de phone_number
export default function useProfileInfoUpdate() {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const form = useForm<ProfileForm>({
    resolver: zodResolver(profileFormSchema),
    mode: "onChange",
    values: user,
  });

  async function updateProfile(data: ProfileForm) {
    if (!user) return;

    const changedValues = getChangedFields(user, data);

    const { data: updatedUser } = await api.patch(
      `/user/${user.id}`,
      changedValues
    );

    setUser(updatedUser);
  }

  const isSubmitting = form.formState.isSubmitting;
  const isSubmitDisabled =
    !form.formState.isValid || !form.formState.isDirty || isSubmitting;

  return {
    form,
    isSubmitting,
    isSubmitDisabled,
    updateProfile,
  };
}
