import { Controller, UseFormReturn } from "react-hook-form";
import { Separator } from "@/components/ui/separator";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import { CheckoutStep } from "../cart";
import { CheckoutSchema } from "../schemas/checkout.schemas";
import { useBusinessStore } from "@/pages/business/store/business.store";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { cn } from "@/lib/utils";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { MoneyInput } from "@/components/inputs/money.input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Dispatch, SetStateAction, useState } from "react";

export default function PaymentStep({
  currentStep,
  form,
}: {
  currentStep: CheckoutStep;
  form: UseFormReturn<CheckoutSchema>;
}) {
  const [exchangeAmountDialogOpen, setExchangeAmountDialogOpen] =
    useState(false);

  const business = useBusinessStore((state) => state.business);
  const calculateShoppingCartSubtotal = useBusinessStore(
    (state) => state.calculateShoppingCartSubtotal
  );
  const calculateShoppingCartTotal = useBusinessStore(
    (state) => state.calculateShoppingCartTotal
  );
  const calculateShoppingCartDeliveryFee = useBusinessStore(
    (state) => state.calculateShoppingCartDeliveryFee
  );

  const paymentMethods = business?.metadata?.paymentMethods || [];
  const total = calculateShoppingCartTotal();
  const subtotal = calculateShoppingCartSubtotal();
  const deliveryFee = calculateShoppingCartDeliveryFee();

  const paymentError = form.formState.errors.paymentMethod;

  if (currentStep !== "payment") return null;

  return (
    <div className="flex flex-col flex-1 p-4 gap-6">
      <div className="flex-1 flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Método de pagamento</h2>

        {paymentError && (
          <Alert variant="destructive" className="animate-shake">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Selecione um método de pagamento para continuar
            </AlertDescription>
          </Alert>
        )}

        <Controller
          name="paymentMethod"
          control={form.control}
          render={({ field }) => (
            <div className="flex flex-col gap-3">
              {paymentMethods.map((method) => (
                <div
                  key={method.id}
                  onClick={() => {
                    field.onChange(method);

                    if (method.flag === "Dinheiro") {
                      setExchangeAmountDialogOpen(true);
                    } else {
                      form.setValue("changeAmount", 0);
                    }
                  }}
                  className={cn(
                    "flex items-center justify-between p-4 rounded-lg border-2 cursor-pointer transition-all",
                    "hover:border-primary/50 hover:bg-primary/5",
                    {
                      "border-primary bg-primary/5":
                        field.value?.id === method.id && !paymentError,
                      "border-destructive bg-destructive/5":
                        field.value?.id === method.id && paymentError,
                      "border-input": field.value?.id !== method.id,
                    }
                  )}
                >
                  <div className="flex items-center gap-3">
                    <div className="relative">
                      <div
                        className={cn(
                          "w-6 h-6 rounded-full border-2 flex items-center justify-center",
                          {
                            "border-primary":
                              field.value?.id === method.id && !paymentError,
                            "border-destructive":
                              field.value?.id === method.id && paymentError,
                            "border-muted-foreground":
                              field.value?.id !== method.id,
                          }
                        )}
                      >
                        {field.value?.id === method.id && (
                          <div
                            className={cn("w-3 h-3 rounded-full", {
                              "bg-primary": !paymentError,
                              "bg-destructive": paymentError,
                            })}
                          />
                        )}
                      </div>
                    </div>
                    <img
                      src={
                        PAYMENT_FLAGS[method.flag as keyof typeof PAYMENT_FLAGS]
                          .image
                      }
                      alt={method.name}
                      className="w-6 h-6"
                    />
                    <span className="font-medium">{method.name}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
      </div>

      {form.watch("paymentMethod") &&
        form.watch("paymentMethod.flag") === "Dinheiro" && (
          <div className="w-full flex flex-col gap-4">
            <h1 className="text-lg font-semibold text-label-primary">
              Valor do troco em dinheiro
            </h1>
            <div className="w-full flex flex-col items-start justify-center gap-4">
              <Controller
                name="changeAmount"
                control={form.control}
                render={({ field }) => (
                  <MoneyInput
                    value={field.value}
                    onValueChange={field.onChange}
                    className={cn("", {
                      "border-red-500": form.formState.errors.changeAmount,
                    })}
                  />
                )}
              />
            </div>
          </div>
        )}

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Resumo de valores</h2>
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex justify-between">
            <span className="text-label-secondary">Subtotal</span>
            <span>{formatMoneyValue(subtotal)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-label-secondary">Taxa de entrega</span>
            <span className="text-label-secondary">
              {formatMoneyValue(deliveryFee)}
            </span>
          </div>
          <Separator />
          <div className="flex justify-between font-medium">
            <span className="text-label-primary">Total</span>
            <span className="text-label-primary">
              {formatMoneyValue(total)}
            </span>
          </div>
        </div>
      </div>

      <ExchangeAmountDialog
        open={exchangeAmountDialogOpen}
        setOpen={setExchangeAmountDialogOpen}
        form={form}
      />
    </div>
  );
}

function ExchangeAmountDialog({
  open,
  setOpen,
  form,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  form: UseFormReturn<CheckoutSchema>;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px] flex justify-start  flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">
            Troco necessário
          </DialogTitle>
          <DialogDescription>
            Você selecionou pagamento em dinheiro. Por favor, informe o valor
            para troco:
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <div className="relative">
            <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
              R$
            </span>
            <Controller
              name="changeAmount"
              control={form.control}
              render={({ field }) => (
                <MoneyInput
                  value={field.value || 0}
                  onValueChange={field.onChange}
                  className={
                    form.formState.errors.changeAmount ? "border-red-500" : ""
                  }
                />
              )}
            />
          </div>
        </div>
        <DialogFooter className="sm:justify-between flex gap-4">
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            className="w-full sm:w-auto"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => setOpen(false)}
            className="w-full sm:w-auto bg-green-600 hover:bg-green-600 text-white"
          >
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
