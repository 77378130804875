import globalApi from "@/config/globalApi.config";

export async function postDiscordMessage(data: {
  channel: string;
  message: string;
}): Promise<void> {
  try {
    await globalApi.post("/discord/message", data);
  } catch (e) {
    console.error(e);
    throw new Error("Error sending message to discord");
  }
}
