import NoFoodImageFallback from "@/components/fallbacks/no-food-image.fallback";
import { cn } from "@/lib/utils";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import getS3Image from "@/utils/getS3Image.util";
import { IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";

type CartItemProps = {
  item: ShoppingCartItem;
};

export default function CartItem({ item }: CartItemProps) {
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const setShoppingCart = useBusinessStore((state) => state.setShoppingCart);

  const itemQuantity = item.quantity;

  function onRemoveItemFromCart() {
    const newCart = {
      ...shoppingCart,
      items: shoppingCart.items.filter((i) => i.id !== item.id),
    };
    setShoppingCart(newCart);
  }

  function onDecrement() {
    if (itemQuantity === 1) {
      onRemoveItemFromCart();
    } else {
      const newCart = {
        ...shoppingCart,
        items: shoppingCart.items.map((i) =>
          i.id === item.id ? { ...i, quantity: i.quantity - 1 } : i
        ),
      };
      setShoppingCart(newCart);
    }
  }

  function onIncrement() {
    const newCart = {
      ...shoppingCart,
      items: shoppingCart.items.map((i) =>
        i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
      ),
    };
    setShoppingCart(newCart);
  }

  return (
    <div className="w-full rounded-md p-4 border flex items-start justify-center gap-4">
      <div className="">
        {item.logo_url ? (
          <img
            src={getS3Image(item.logo_url)}
            alt={item.title}
            className="min-w-12 min-h-12 max-w-12 max-h-12 rounded-lg object-cover"
          />
        ) : (
          <NoFoodImageFallback className="w-12 h-12" />
        )}
      </div>
      <div className="flex-1">
        <div className="flex items-center justify-between w-full">
          <h1 className="font-semibold text-label-primary">
            {item.quantity}x {item.title}
          </h1>
          <div className="flex items-center gap-4 border py-1 px-3 rounded-lg">
            <button
              type="button"
              onClick={onDecrement}
              className={cn("rounded-full text-lg font-semibold", {
                "hover:bg-transparent bg-transparent p-0": itemQuantity === 1,
              })}
            >
              {itemQuantity === 1 ? (
                <IconTrash color="gray" size={20} />
              ) : (
                <IconMinus size={20} color="gray" />
              )}
            </button>

            <span className="text-label-primary text-base">{itemQuantity}</span>
            <button
              type="button"
              onClick={onIncrement}
              className={cn("rounded-full text-lg font-semibold")}
            >
              <IconPlus size={20} color="green" />
            </button>
          </div>
        </div>
        <p>{formatMoneyValue(item.quantity * item.price)}</p>
        <div className="flex flex-col">
          {item.optionals?.map((optional) => (
            <p className="text-sm text-label-secondary">
              <span className="text-label-primary font-semibold">1x </span>
              {optional.title}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
