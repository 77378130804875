import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import Addresses from "@/pages/address/components/addresses";
import { Dispatch, ReactNode, SetStateAction } from "react";

type SelectAddressSheetProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
};
export function SelectAddressSheet({
  children,
  open,
  setOpen,
}: SelectAddressSheetProps) {
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      {children && <SheetTrigger>{children}</SheetTrigger>}
      <SheetContent className="p-0 sm:max-w-[unset] w-full">
        <div className="px-4">
          <Addresses />
        </div>
      </SheetContent>
    </Sheet>
  );
}
