import { useEffect, useState } from "react";
import { IconCheck, IconLogout } from "@tabler/icons-react";
import { Button } from "./ui/button";
import Spinner from "./ui/spinner";
import { useAuth } from "@/context/auth.context";

export default function LogoutButton() {
  const { signOut } = useAuth();
  const [isLogginOut, setIsLogginOut] = useState(false);
  const [isConfirmingLogout, setIsConfirmingLogout] = useState(false);

  async function onConfirmLogout() {
    setIsLogginOut(true);
    await signOut();
  }

  useEffect(() => {
    if (isConfirmingLogout) {
      setTimeout(() => {
        setIsConfirmingLogout(false);
      }, 3000);
    }
  }, [isConfirmingLogout]);

  if (!isConfirmingLogout) {
    return (
      <Button
        onClick={() => setIsConfirmingLogout(true)}
        className="w-full items-center justify-start text-base hover:bg-transparent text-black  bg-transparent flex gap-4 rounded-lg p-2"
      >
        <IconLogout size={24} />
        <p className="font-semibold">Sair</p>
      </Button>
    );
  }

  return (
    <Button
      className="w-full items-center justify-start text-base hover:bg-red-500 text-black border bg-red-400  shadow-md px-4 py-2 flex gap-2 rounded-lg"
      onClick={onConfirmLogout}
    >
      {!isLogginOut && <IconCheck size={16} color="#fff" />}
      {isLogginOut && <Spinner color="#fff" />}
      <p className="text-white">Confirmar</p>
    </Button>
  );
}
