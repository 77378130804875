import { useUserStore } from "@/pages/profile/store/user.store";
import AddressCard from "./address.card";

export default function UserAddresses() {
  const user = useUserStore((state) => state.user);

  if (!user) return null;

  const addresses = user.addresses || [];

  if (addresses.length === 0) {
    return (
      <div className="w-full flex items-center justify-start">
        <p className="text-label-secondary text-center">
          Você não possuí nenhum endereço cadastrado
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start">
      {addresses.map((address, index) => (
        <AddressCard address={address} key={index} />
      ))}
    </div>
  );
}
