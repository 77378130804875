/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SignInSchema,
  SignUpSchema,
} from "@/components/authentication/auth.actions";
import api from "@/config/api.config";
import globalApi from "@/config/globalApi.config";
import { useUserStore } from "@/pages/profile/store/user.store";
import { User } from "@/types/user.type";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface AuthContextData {
  user: User | undefined;
  signed: boolean;
  loading: boolean;
  signIn(data: SignInSchema): Promise<void>;
  signOut(): Promise<void>;
  signUp(data: SignUpSchema): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  async function signIn(data: SignInSchema) {
    try {
      setLoading(true);
      const { data: response } = await api.post("/user/sign-in", data);

      setUser(response.user);
      localStorage.setItem("CLIENT_AUTH_TOKEN", response.token);
      api.defaults.headers.Authorization = `Bearer ${response.token}`;
      globalApi.defaults.headers.Authorization = `Bearer ${response.token}`;
    } catch (error) {
      console.error("Sign in failed:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function signUp(data: SignUpSchema) {
    try {
      setLoading(true);
      const { data: response } = await api.post("/user/sign-up", data);

      setUser(response.user);
      localStorage.setItem("CLIENT_AUTH_TOKEN", response.token);
    } catch (err: any) {
      console.error("Sign up failed:", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  async function signOut() {
    setLoading(true);

    setTimeout(() => {
      clearAll();
      setLoading(false);
    }, 300);
  }

  async function verifyToken() {
    const token = localStorage.getItem("CLIENT_AUTH_TOKEN");

    try {
      setLoading(true);
      const { data: response } = await api.post("/user/verify-token", {
        token,
      });

      setUser(response.user);
      localStorage.setItem("CLIENT_AUTH_TOKEN", response.token);
      api.defaults.headers.Authorization = `Bearer ${response.token}`;
      globalApi.defaults.headers.Authorization = `Bearer ${response.token}`;
    } catch (error) {
      if (token) {
        console.error("Token validation failed:", error);
      }

      clearAll();
    } finally {
      setLoading(false);
    }
  }

  function clearAll() {
    localStorage.removeItem("CLIENT_AUTH_TOKEN");
    delete api.defaults.headers.Authorization;
    delete globalApi.defaults.headers.Authorization;

    setUser(undefined);
    // TODO: Implement clear all on stores
    // TODO: Create order and clear all
  }

  const logoutOnTokenRemoved = useCallback(() => {
    const token = localStorage.getItem("token");

    if (!token) return clearAll();

    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem("CLIENT_AUTH_TOKEN");

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  globalApi.interceptors.request.use((config) => {
    const token = localStorage.getItem("CLIENT_AUTH_TOKEN");

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  useEffect(() => {
    window.addEventListener("storage", logoutOnTokenRemoved);

    return () => {
      window.removeEventListener("storage", logoutOnTokenRemoved);
    };
  }, [logoutOnTokenRemoved]);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    signed: !!user,
    user,
    loading,
    signIn,
    signOut,
    signUp,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export default AuthContext;
