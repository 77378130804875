import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Business, BusinessWekDays } from "@/types/business.type";
import { IconChevronRight, IconMapPin, IconPhone } from "@tabler/icons-react";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import BusinessLogo from "@/components/fallbacks/logos/business-logo";

type BusinessSheetProps = {
  business: Business;
};

const weekDayMap: Record<BusinessWekDays, string> = {
  "1": "Domingo",
  "2": "Segunda-feira",
  "3": "Terça-feira",
  "4": "Quarta-feira",
  "5": "Quinta-feira",
  "6": "Sexta-feira",
  "7": "Sábado",
};

function formatBusinessHours(hours: Business["metadata"]["hours"]) {
  return hours
    .filter((hour) => hour.start_time && hour.end_time)
    .map((hour) => ({
      day: weekDayMap[hour.day_of_week],
      hours: `${hour.start_time} às ${hour.end_time}`,
      isOpen: hour.is_open,
    }))
    .sort((a, b) => {
      const dayOrder = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ];
      return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
    });
}

export default function BusinessSheet({ business }: BusinessSheetProps) {
  const businessHours = formatBusinessHours(business.metadata.hours);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="p-0 hover:bg-transparent hover:text-primary transition-colors"
        >
          <IconChevronRight className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent className="w-full sm:max-w-md p-0 overflow-y-auto">
        <SheetHeader className="px-4 py-4 flex-row items-center justify-between border-b bg-background/95 backdrop-blur-sm z-10">
          <div className="flex items-center gap-3">
            <SheetTitle className="text-lg font-semibold">
              Sobre o estabelecimento
            </SheetTitle>
          </div>
        </SheetHeader>

        <div className="flex flex-col gap-8 px-6 py-8">
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-4">
              <BusinessLogo
                logo_url={business.logo_url}
                className="min-w-16 min-h-16 max-w-16 max-h-16 rounded-full border"
              />
              <div className="flex flex-col gap-1">
                <h1 className="text-lg font-bold">{business.name}</h1>
                {business.description && (
                  <p className="text-sm text-muted-foreground leading-relaxed">
                    {business.description}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold">Horário de atendimento</h2>
            <div className="flex flex-col gap-4 bg-secondary/10 rounded-xl  hover:bg-secondary/20 transition-colors">
              {businessHours.map((hour, index) => (
                <div
                  key={index}
                  className="flex justify-between text-sm items-center"
                >
                  <span className="font-medium">{hour.day.split("-")[0]}</span>
                  <span
                    className={`px-3 py-1 rounded-lg ${
                      hour.isOpen
                        ? "bg-primary/10 text-primary font-medium"
                        : "bg-muted text-muted-foreground"
                    }`}
                  >
                    {hour.isOpen ? hour.hours : "Fechado"}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <Separator />

          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold">Formas de pagamento</h2>
            <div className="grid grid-cols-2 gap-3">
              {(business.metadata.paymentMethods || []).map((method, i) => (
                <div
                  className="flex items-center gap-3 p-3 rounded-xl border bg-card hover:border-primary/50 hover:shadow-sm transition-all"
                  key={i}
                >
                  <div className="w-8 h-8 flex items-center justify-center bg-white rounded-lg shadow-sm">
                    <img
                      src={
                        PAYMENT_FLAGS[method.flag as keyof typeof PAYMENT_FLAGS]
                          .image
                      }
                      alt={method.name}
                      className="min-w-6 min-h-6 max-w-6 max-h-6 object-contain"
                    />
                  </div>
                  <p className="text-sm font-medium">{method.name}</p>
                </div>
              ))}
            </div>
          </div>

          <Separator />

          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold">Contato</h2>
            <a
              href={`tel:${business.phone_number}`}
              className="flex items-center gap-3 p-4 rounded-xl border hover:border-primary/50 hover:shadow-sm transition-all bg-card"
            >
              <div className="min-w-10 min-h-10 max-w-10 max-h-10 rounded-lg bg-primary/10 flex items-center justify-center">
                <IconPhone size={20} className="text-primary" />
              </div>
              <span className="text-sm font-medium">
                {business.phone_number}
              </span>
            </a>
          </div>

          {business.address && (
            <>
              <Separator />
              <div className="flex flex-col gap-4">
                <h2 className="text-lg font-semibold">Endereço</h2>
                <div className="flex items-start gap-3 p-4 rounded-xl border bg-card hover:border-primary/50 hover:shadow-sm transition-all">
                  <div className="min-w-10 min-h-10 max-w-10 max-h-10 rounded-lg bg-primary/10 flex items-center justify-center">
                    <IconMapPin size={20} className="text-primary" />
                  </div>
                  <span className="text-sm">{business.address}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}
