import Spinner from "@/components/ui/spinner";
import { cn } from "@/lib/utils";

export type GoogleSearchAddresses = {
  description: string;
  place_id: string;
};

export default function GoogleMapsAddresses({
  addresses,
  handleSelectAddress,
  selectedAddressId,
  isFetchingSelectedAddress,
}: {
  addresses: GoogleSearchAddresses[];
  handleSelectAddress: (address: GoogleSearchAddresses) => void;
  selectedAddressId: string | null;
  isFetchingSelectedAddress: boolean;
}) {
  if (addresses.length === 0) {
    return (
      <div className="w-full flex items-center justify-start">
        <p className="text-label-secondary text-center">
          Nenhum endereço encontrado.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-6 items-start justify-start cursor-pointer">
      {addresses.map((address, index) => (
        <div
          key={index}
          onClick={() => handleSelectAddress(address)}
          className="w-full"
        >
          <div
            className={cn(
              "p-4 rounded-lg border w-full flex items-center justify-between",
              {
                "border-green-300": selectedAddressId === address.place_id,
              }
            )}
          >
            <div className="w-full">
              <h1 className="text-label-primary font-semibold">
                {address.description}
              </h1>
            </div>
            {isFetchingSelectedAddress &&
              selectedAddressId === address.place_id && (
                <div>
                  <Spinner />
                </div>
              )}
          </div>
        </div>
      ))}
    </div>
  );
}
