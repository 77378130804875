import BusinessHeader from "../components/business.header";
import useBusiness from "../hook/business.hook";
import LoadingBusinessFallback from "../components/fallbacks/loading-business.fallback";
import NotfoundBusiness from "../components/fallbacks/notfound.business";
import BusinessFeatures from "../components/business.features";
import BusinessCategories from "../components/business.categories";

export default function BusinessPage() {
  const {
    isLoading,
    business,
    menuCategories,
    setSelectedCategory,
    selectedCategory,
    search,
    filteredMenuCategories,
    isCategoriesEmpty,
    setSearch,
  } = useBusiness();

  if (isLoading) return <LoadingBusinessFallback />;
  if (!business) return <NotfoundBusiness />;

  return (
    <div className="w-full flex flex-col min-h-screen bg-gray-50">
      <BusinessHeader business={business} />
      <BusinessFeatures
        categories={menuCategories}
        onCategoryChange={setSelectedCategory}
        selectedCategory={selectedCategory}
        searchValue={search}
        onSearch={setSearch}
      />
      <BusinessCategories
        filteredMenuCategories={filteredMenuCategories}
        isCategoriesEmpty={isCategoriesEmpty}
      />
    </div>
  );
}
