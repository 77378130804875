import { Order } from "@/types/order.type";

type OrderStatusTimelineProps = {
  order: Order;
};

export default function OrderStatusTimeline({
  order,
}: OrderStatusTimelineProps) {
  if (order.status === "canceled") {
    return (
      <div className=" p-2 bg-red-100 border border-red-300 rounded-md">
        <p className="text-red-600 font-medium">Pedido cancelado</p>
      </div>
    );
  }

  const statusSteps = [
    { status: "pending", label: "Pedido realizado" },
    { status: "production", label: "Pedido em produção" },
    { status: "delivering", label: "Saiu para entrega" },
    { status: "done", label: "Entregue" },
  ];

  const currentStepIndex = statusSteps.findIndex(
    (step) => step.status === order.status
  );

  return (
    <div className="flex flex-col space-y-1 ">
      {statusSteps.map((step, index) => (
        <div key={step.status} className="flex items-center">
          <div className="relative">
            <div
              className={`w-3 h-3 rounded-full ${
                index <= currentStepIndex ? "bg-blue-600" : "bg-gray-300"
              }`}
            />
            {index < statusSteps.length - 1 && (
              <div
                className={`absolute left-1/2 top-3 w-0.5 h-6 -translate-x-1/2 ${
                  index < currentStepIndex ? "bg-blue-600" : "bg-gray-300"
                }`}
              />
            )}
          </div>
          <div className="ml-4">
            <p
              className={`text-sm ${
                index <= currentStepIndex
                  ? "text-blue-600 font-medium"
                  : "text-gray-500"
              }`}
            >
              {step.label}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
