import { cn } from "@/lib/utils";
import useErrorPage from "../hook/error-page.hook";

export function ErrorPage({ error }: { error: Error }) {
  useErrorPage({ error });

  function handleClearAllDataFromBrowser() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/orders";
  }

  return (
    <div className="bg-wite flex min-h-screen w-full flex-col items-center justify-between">
      <div />
      <div className="flex w-full max-w-[378px] flex-col items-start gap-6 p-6">
        <div className="flex flex-col items-start gap-4">
          <h1 className="text-2xl font-bold">Ação indisponível</h1>
        </div>
        <p className="text-label-secondary font-semibold">
          Isso aconteceu devido a um problema durante a execução de um processo
          no Pedido.io
        </p>
        <button
          type="button"
          className={cn(
            "w-fit rounded-xl bg-black px-4 py-2",
            "font-inter text-base font-semibold text-white"
          )}
          onClick={handleClearAllDataFromBrowser}
        >
          Voltar ao início
        </button>
      </div>
      <div />
    </div>
  );
}
