import { UseFormReturn } from "react-hook-form";
import { CheckoutStep } from "../cart";
import { CheckoutSchema } from "../schemas/checkout.schemas";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { useUserStore } from "@/pages/profile/store/user.store";
import formatPhoneNumber from "@/utils/formatPhoneNumber.util";
import { IconMapPin, IconUser } from "@tabler/icons-react";
import { Separator } from "@/components/ui/separator";
import CartItem from "../components/cart-item";
import { PAYMENT_FLAGS } from "@/data/paymentFlags";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import { Textarea } from "@/components/ui/textarea";
import { useEffect } from "react";

export default function SummaryStep({
  currentStep,
  form,
}: {
  currentStep: CheckoutStep;
  form: UseFormReturn<CheckoutSchema>;
}) {
  const user = useUserStore((state) => state.user);
  const shoppingCart = useBusinessStore((state) => state.shoppingCart);
  const calculateShoppingCartTotal = useBusinessStore(
    (state) => state.calculateShoppingCartTotal
  );
  const calculateShoppingCartSubtotal = useBusinessStore(
    (state) => state.calculateShoppingCartSubtotal
  );
  const calculateShoppingCartDeliveryFee = useBusinessStore(
    (state) => state.calculateShoppingCartDeliveryFee
  );

  const userAddresses = user?.addresses || [];
  const userMainAddress = userAddresses.find((address) => address.is_selected);

  const total = calculateShoppingCartTotal();
  const subtotal = calculateShoppingCartSubtotal();
  const deliveryFee = calculateShoppingCartDeliveryFee();

  const paymentMethod = form.watch("paymentMethod");

  useEffect(() => {
    if (currentStep !== "summary") return;

    form.trigger();
  }, [currentStep, form]);

  if (currentStep !== "summary") return null;

  return (
    <div className="flex flex-col flex-1 p-4 gap-6">
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Informações para entrega</h2>
        <div className="flex flex-col gap-4">
          {user && (
            <div className="flex items-center gap-4">
              <IconUser size={24} className="text-primary" />
              <div>
                <h2 className="font-semibold text-label-primary">
                  {user.name}
                </h2>
                <p className="text-label-secondary text-sm">
                  {formatPhoneNumber(user.phone_number)}
                </p>
              </div>
            </div>
          )}
          {userMainAddress && (
            <div className="flex items-center justify-start gap-4">
              <IconMapPin size={24} className="text-primary" />
              <div className="flex items-start justify-start flex-col">
                <p className="text-label-primary font-semibold">
                  {userMainAddress.street}, {userMainAddress.number}
                </p>
                <p className="text-label-secondary">
                  {userMainAddress.district}, {userMainAddress.city} -{" "}
                  {userMainAddress.state}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <Separator />

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Itens do pedido</h2>
        <div className="flex flex-col gap-4">
          {shoppingCart.items.map((item) => (
            <CartItem key={item.id} item={item} />
          ))}
        </div>
      </div>

      <Separator />

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Pagamento</h2>
        <div
          className={`flex items-center justify-between p-3 rounded-lg border cursor-pointer`}
        >
          <div className="flex items-center gap-3">
            <img
              src={
                PAYMENT_FLAGS[paymentMethod.flag as keyof typeof PAYMENT_FLAGS]
                  .image
              }
              className="w-5 h-5"
            />
            <span className="font-medium">{paymentMethod.name}</span>
          </div>
        </div>
        {form.watch("changeAmount") ? (
          <div className="flex items-center justify-start gap-2">
            <h3 className="text-label-primary font-medium text-sm">
              Troco para:
            </h3>
            <p className="text-sm">
              {formatMoneyValue(form.watch("changeAmount") || 0)}
            </p>
          </div>
        ) : null}
      </div>

      <Separator />

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Observações do pedido</h2>
        <div className="flex flex-col gap-4">
          <Textarea
            {...form.register("description")}
            placeholder="Observações do pedido"
          />
        </div>
      </div>

      <Separator />

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Resumo de valores</h2>
        <div className="flex flex-col gap-2 text-sm">
          <div className="flex justify-between">
            <span className="text-label-secondary">Subtotal</span>
            <span>{formatMoneyValue(subtotal)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-label-secondary">Taxa de entrega</span>
            <span className="text-label-secondary">
              {formatMoneyValue(deliveryFee)}
            </span>
          </div>
          <Separator />
          <div className="flex justify-between font-medium">
            <span className="text-label-primary">Total</span>
            <span className="text-label-primary">
              {formatMoneyValue(total)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
