import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { IconChevronRight } from "@tabler/icons-react";
import { cn } from "@/lib/utils";

type NavItemProps = {
  href: string;
  icon: JSX.Element;
  label: string;
  isActive: boolean;
  tooltipText: string;
  handleOnClick?: () => void;
};

export default function NavItem({
  href,
  icon,
  label,
  isActive,
  tooltipText,
  handleOnClick,
}: NavItemProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild onClick={handleOnClick}>
        <Link
          to={href}
          className={cn(
            "flex items-center justify-between p-2 w-full rounded-lg text-muted-foreground transition-colors hover:text-foreground gap-4",
            {
              "bg-accent text-accent-foreground border": isActive,
            }
          )}
        >
          <div className="flex items-center justify-center gap-4">
            {icon}
            <span className={cn("text-label-primary font-semibold")}>
              {label}
            </span>
          </div>
          <IconChevronRight
            size={16}
            className={cn("stroke-label-secondary")}
          />
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{tooltipText}</TooltipContent>
    </Tooltip>
  );
}
