import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "@/styles/globals.css";

import RootLayout from "./layouts/root.layout";
import AuthLayout from "./layouts/auth.layout";
import NotFoundPage from "./pages/404";
import BusinessPage from "./pages/business/pages/business.page";
import OrdersPage from "./pages/orders/pages/orders.page";
import ProfilePage from "./pages/profile/pages/profile.page";
import PublicLayout from "./layouts/public.layout";
import AddressesPages from "./pages/address/pages/addresses.page";
import HomePage from "./pages/home/pages/home.page";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        element: <PublicLayout />,
        path: "/",
        children: [
          { path: "/", element: <HomePage /> },
          { path: "/b/:id", element: <BusinessPage /> },
        ],
      },
      {
        element: <AuthLayout />,
        path: "/",
        children: [
          { path: "/pedidos", element: <OrdersPage /> },
          { path: "/perfil", element: <ProfilePage /> },
          { path: "/enderecos", element: <AddressesPages /> },
        ],
      },

      { path: "*", element: <NotFoundPage /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
