import { MenuItem } from "@/types/menu.type";
import NotFoundMenuCategoryFallback from "./fallbacks/notfound-menuCategory.fallback";
import MenuItemSheet from "./menu/menu.item.sheet";

type BusinessCategoriesProps = {
  isCategoriesEmpty: boolean;
  filteredMenuCategories: Array<{
    id: number;
    title: string;
    menu_items?: MenuItem[];
  }>;
};

export default function BusinessCategories({
  isCategoriesEmpty,
  filteredMenuCategories,
}: BusinessCategoriesProps) {
  return (
    <div className="w-full flex flex-col pb-8">
      <div className="w-full flex-col gap-6 px-6 pt-6">
        {isCategoriesEmpty && <NotFoundMenuCategoryFallback />}
        <div className="flex flex-col gap-8">
          {filteredMenuCategories.map((category) => (
            <div key={category.id} className="flex flex-col gap-4">
              <h2 className="text-lg font-semibold flex flex-col gap-4">
                {category.title}
              </h2>

              <div className="flex flex-col gap-4">
                {category.menu_items?.map((item) => (
                  <MenuItemSheet key={item.id} item={item} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
